body {
  background-color: #3b5998;
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  color: #fff;
}
.container {
  max-width: 960px;
  margin: 0 auto;
  padding: 20px;
  text-align: center;
}
header {
  background-color: #2d4373;
  color: #fff;
  padding: 40px 20px;
  text-align: center;
  position: relative;
}
.logo-container {
  position: absolute;
  top: 50%;
  left: 20px;
  transform: translateY(-50%);
}
.logo {
  width: 30px; /* Default logo size for mobile */
  height: auto;
}
@media (min-width: 768px) {
  .logo {
    width: 100px; /* Logo size for desktop */
  }
}
h1 {
  margin: 0;
  font-size: 30px;
  text-align: center;
}
nav ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
  text-align: center;
}
nav ul li {
  display: inline;
  margin-right: 10px;
}
nav ul li a {
  color: #fff;
  text-decoration: none;
}
main {
  background-color: #fff;
  padding: 20px;
  margin-top: 20px;
  border-radius: 4px;
  color: #333;
  text-align: center;
  display: flex;
  flex-direction: column;
}
h2 {
  color: #3b5998;
  font-size: 24px;
  text-align: center;
  margin-bottom: 20px;
}
p {
  line-height: 1.5;
  text-align: center;
  font-size: 16px;
}
footer {
  background-color: #2d4373;
  color: #fff;
  padding: 10px;
  text-align: center;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.profile-image {
  max-width: 200px;
  height: auto;
  border-radius: 50%;
  margin-bottom: 20px;
}
.chart-image {
  max-width: 100%;
  height: auto;
  margin-bottom: 20px;
}
.description {
  text-align: center;
  max-width: 800px;
  margin: 0 auto;
  padding: 20px;
  flex: 0 0 40%;
}
.description h2 {
  font-size: 32px;
  margin-bottom: 20px;
}
.description p {
  font-size: 20px;
  line-height: 1.6;
  margin-bottom: 20px;
  text-align: center;
}
.loading-screen {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
.loading-logo {
  max-width: 200px;
  max-height: 200px;
}
.image-row {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.image-row-item {
  width: 600px;
  height: 600px;
  object-fit: contain;
  margin: 10px;
}
.app-store-container {
  text-align: center;
  margin-top: 20px;
}
.app-store-image {
  max-width: 200px;
  height: auto;
}
.play-store-container {
  text-align: center;
}
.play-store-image {
  max-width: 230px;
  height: auto;
}
.main-heading {
  text-align: center;
  color: #3b5998;
  font-size: 32px;
  margin-bottom: 20px;
}
.content-wrapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}
.types-section {
  flex: 0 0 60%;
  padding-left: 5%;
  margin-bottom: 20px;
}
.types-image {
  width: 100%;
  max-width: 800px;
  height: auto;
  margin: 0 auto;
}
@media (max-width: 767px) {
  .image-row-item {
    width: 450px;
    height: 450px;
  }
  .content-wrapper {
    flex-direction: column;
  }
  .types-section, .description {
    flex: 0 0 100%;
    padding: 0;
  }
  .types-section {
    overflow: hidden;
    width: 100%;
  }
  .types-image {
    max-width: 290%; /* Increased from 250% to 270% to make it slightly bigger */
    position: relative;
    left: 55%; /* Decreased from 60% to 57% to move it slightly more left */
    transform: translateX(-50%);
  }
  .instagram-container {
    flex: 0 0 100%;
    padding: 0;
    text-align: center;
    margin-top: 20px;
  }
  .instagram-container p {
    margin-bottom: 10px;
  }
  .instagram-image {
    max-width: 160px;
    width: 100%;
    height: auto;
  }
}
